<template>
  <div class="boton__menu">
    <hr class="boton__menu__linea">
    <hr class="boton__menu__linea">
    <hr class="boton__menu__linea">
  </div>
</template>

<script>
export default {
  name: 'BotonHamburgesa',
  props: {
  }
}
</script>
